import { useCallback, useEffect, useState } from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';

export default function useCarousel() {
  const figureOpacity = useColorModeValue('1', '0.6');
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [emblaRef, embla] = useEmblaCarousel(
    {
      dragFree: true,
      align: 'start',
      containScroll: 'trimSnaps',
    },
    [Autoplay({ stopOnMouseEnter: true })]
  );

  const emblaReInit = useCallback(() => embla && embla.reInit(), [embla]);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);

  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;

    embla.on('select', onSelect);
    embla.on('reInit', onSelect);
    embla.on('resize', emblaReInit);
    setTimeout(emblaReInit, 150);

    onSelect();
  }, [embla, onSelect]);

  return {
    figureOpacity,
    prevBtnEnabled,
    nextBtnEnabled,
    scrollPrev,
    scrollNext,
    emblaRef,
  };
}
