import React from 'react';

export enum Position {
  Left = 'left',
  Right = 'right',
}

export type TItem = {
  exact?: boolean;
  name: string;
  to: string;
};

export type Sharer = {
  id: string;
  label: string;
  mandatory: boolean;
  icon?: React.ReactElement;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
};

export type TSharerData = {
  title: string;
  image: string;
  url: string;
  subject: string;
  body: string;
};

export type WidgetProps = {
  position?: Position;
  title?: string | null;
  content?: React.ReactElement;
};

export type ListLinkProps = {
  position: Position;
  items?: TItem[];
};

export type ListShareProps = {
  position: Position;
  data: TSharerData;
};
