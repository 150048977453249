import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

function Sticky({ children, ...restProps }: FlexProps): React.ReactElement {
  return (
    <Flex
      {...restProps}
      position="sticky"
      direction="column"
      sx={{
        display: 'none',
        '@media (min-width: 992px)': {
          display: 'flex',
        },
      }}
    >
      {children}
    </Flex>
  );
}

export { Sticky as default };
