import { gql } from 'graphql.macro';

const GET_STORY = gql`
  query Story($hash: String!, $language: String!, $withCounter: Boolean) {
    story(hash: $hash, language: $language, withCounter: $withCounter) {
      hash
      views
      createdNews {
        hash
        url
        info {
          slug
          title
          description
          keywords
          intro
          lead
          content
        }
        source {
          name
        }
      }
      images {
        hash
        name
        #main
        #included
        created_at
      }
      videos {
        src
      }
      created_at
    }
  }
`;

export default GET_STORY;
