export const centerPopup = ({ url, title, w = 550, h = 350 }: any) => {
  const top = window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - h / 2;
  const left = window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - w / 2;

  const popup = window.open(
    url,
    title,
    `
      status: 'no',
      location: 'no',
      resizable: 'no',
      directories: 'no',
      scrollbars: 'no',
      menubar: 'no',
      toolbar: 'no',
      width=${w},
      height=${h},
      top=${top},
      left=${left},
    `
  );

  popup && popup.focus();
};
