import React from 'react';
import { FiShare } from 'react-icons/fi';
import { Flex, Menu, MenuList, MenuItem, MenuGroup, MenuButton, IconButton, keyframes } from '@chakra-ui/react';

import sharers from '../../data/sharers';

// const shake = keyframes`
//   from {
//     transform: scale3d(1, 1, 1);
//   }
//
//   10%, 20% {
//     transform: rotate3d(1, 1, 1, -5deg);
//   }
//
//   30%, 50%, 70%, 90% {
//     transform: rotate3d(1, 1, 1, 5deg);
//   }
//
//   40%, 60%, 80% {
//     transform: rotate3d(1, 1, 1, -5deg);
//   }
//
//   to {
//     transform: scale3d(1, 1, 1);
//   }
// `;

function Share({ data }: any): React.ReactElement {
  const radius = [
    '10% 30% 40% 35%',
    '40% 55% 40% 20%',
    '20% 40% 55% 40%',
    '35% 55% 45% 30%',
    '30% 45% 55% 35%',
    '25% 65% 45% 35%',
    '35% 55% 65% 25%',
    '15% 25% 20% 25%',
    '25% 20% 25% 15%',
    '35% 40% 30% 10%',
  ];

  return (
    <Menu placement="top-end" isLazy gutter={20}>
      <MenuButton
        as={IconButton}
        isRound
        size="sm"
        variant="ghost"
        aria-label="Share"
        color="var(--colors-button)"
        zIndex={2}
        icon={
          // <Flex animation={`${shake} infinite 1.5s`}>
          <Flex>
            <FiShare size="1.25rem" />
          </Flex>
        }
        _focus={{
          boxShadow: 'none',
        }}
        _active={{
          background: 'var(--colors-buttonShareBackground)',
        }}
        _hover={{
          background: 'var(--colors-buttonShareBackground)',
        }}
      />
      <MenuList
        borderColor="var(--colors-menu)"
        backgroundColor="var(--colors-menuBackground)"
        boxShadow="0 5px 16px rgb(0 0 0 / 5%)"
        minWidth="0"
        zIndex={3}
        p="0"
      >
        <MenuGroup>
          {sharers.map((option) => (
            <MenuItem
              key={option.id}
              data-url={data.url}
              data-title={data.title}
              data-image={data.image}
              data-subject={data.subject}
              data-body={data.body}
              icon={option?.icon}
              iconSpacing={1}
              lineHeight={1.15}
              fontSize="12px"
              borderRadius={radius[Math.floor(Math.random() * radius.length)]}
              transition="all .1s ease-in-out"
              p="0.5rem"
              background="var(--colors-menuBackground)"
              _focus={{
                background: 'transparent',
              }}
              _active={{
                background: 'transparent',
              }}
              _hover={{
                background: 'var(--colors-buttonShareBackground)',
              }}
              onClick={option.onClick}
            >
              {option.label}
            </MenuItem>
          ))}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}

export default Share;
