import React, { useCallback } from 'react';
import { VStack, Box } from '@chakra-ui/react';

import sharers from '../../../../data/sharers';
import { Position, ListShareProps, Sharer } from '../../types';

const ulListThemes = {
  [Position.Left]: {
    borderRight: '1px solid var(--colors-widgetBorder)',
  },
  [Position.Right]: {
    borderLeft: '1px solid var(--colors-widgetBorder)',
  },
};

const liListThemes = {
  [Position.Left]: {
    p: '0.25rem 1rem 0 0',
  },
  [Position.Right]: {
    p: '0.25rem 0 0 1rem',
  },
};

function ListShare({ position, data }: ListShareProps): React.ReactElement {
  const filteredItems = sharers?.filter((item) => item.mandatory);
  const ulStyles = ulListThemes[position];
  const liStyles = liListThemes[position];

  const renderItem = useCallback(
    (item: Sharer) => (
      <Box
        {...liStyles}
        key={item.label}
        data-url={data.url}
        data-title={data.title}
        data-image={data.image}
        data-subject={data.subject}
        data-body={data.body}
        as="li"
        w="100%"
        color="var(--colors-widgetText)"
        transition="color .1s ease-in-out"
        display="inline-flex"
        textDecoration="none"
        fontSize="0.875rem"
        cursor="pointer"
        _focus={{
          boxShadow: 'none',
        }}
        _active={{
          color: 'var(--colors-widgetTextHover)',
        }}
        _hover={{
          color: 'var(--colors-widgetTextHover)',
        }}
        onClick={item.onClick}
      >
        {item.label}
      </Box>
    ),
    [liStyles]
  );

  return (
    <VStack {...ulStyles} as="ul" w="100%" listStyleType="none" _notFirst={{ p: '1rem 0 0 0' }}>
      {filteredItems?.map(renderItem)}
    </VStack>
  );
}

export default ListShare;
