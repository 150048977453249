import React, { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { VStack, Box, Flex, LinkOverlay } from '@chakra-ui/react';

import { hexToRgba } from '../../../../utils';
import { TCarousel, TSlide } from './types';
import useCarousel from './useCarousel';
import NextButton from './NextButton';
import PrevButton from './PrevButton';

function Carousel({ slides }: TCarousel): React.ReactElement {
  const { figureOpacity, prevBtnEnabled, nextBtnEnabled, scrollPrev, scrollNext, emblaRef } = useCarousel();

  const renderSlide = useCallback(
    (slide: TSlide) => (
      <Box
        key={slide.hash || slide.name}
        as="li"
        display="flex"
        alignItems="end"
        flex="0 0 13.4rem"
        position="relative"
        opacity={figureOpacity}
        backgroundImage={slide.img && `url(${slide.img})`}
        backgroundSize="cover"
        backgroundColor="#ccc"
        borderRadius="0.375rem"
        height="200px"
        sx={{
          m: '0 !important',
          '&::after': {
            position: 'absolute',
            content: '""',
            height: '100%',
            width: '100%',
            zIndex: 2,
            borderRadius: '0.375rem',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `linear-gradient(180deg, transparent 45.73%, ${hexToRgba('#000000', 1)} 100%)`,
          },
        }}
      >
        <LinkOverlay
          as={RouterLink}
          to={slide.to}
          zIndex={99999999}
          _before={{
            zIndex: 1,
          }}
          _focus={{
            boxShadow: 'none',
          }}
          _hover={{
            textDecoration: 'none',
          }}
        >
          <Flex
            alignItems="end"
            fontSize="0.95rem"
            fontWeight={700}
            color="white"
            m="0 1rem 1rem"
            letterSpacing="-0.2px"
            overflow="hidden"
            lineHeight="1.5"
            height="4rem"
            zIndex={3}
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '3',
            }}
          >
            {slide.name}
          </Flex>
        </LinkOverlay>
      </Box>
    ),
    [figureOpacity]
  );

  return (
    <Flex position="relative" w="100%">
      {slides?.length > 0 && (
        <Flex overflow="hidden" w="100%" ref={emblaRef}>
          <VStack as="ul" listStyleType="none" flexDirection="row" gap={6}>
            {slides?.map(renderSlide)}
          </VStack>
        </Flex>
      )}
      {(prevBtnEnabled || nextBtnEnabled) && (
        <>
          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </>
      )}
    </Flex>
  );
}

export default Carousel;
