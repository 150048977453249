import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';

import Carousel from './components/Carousel';
import ListLink from './components/ListLink';
import ListShare from './components/ListShare';
import { Position, WidgetProps } from './types';

const widgetThemes = {
  [Position.Left]: {
    p: '0 2rem 1rem 0',
  },
  [Position.Right]: {
    p: '0 0 1rem 2rem',
  },
};

function Widget(props: WidgetProps): React.ReactElement {
  const widgetStyles = props?.position ? widgetThemes[props?.position] : {};

  return (
    <Flex
      {...widgetStyles}
      direction="column"
      alignItems="flex-start"
      transition="opacity .3s ease"
      overflow="hidden"
      opacity="0.6"
      w="100%"
      _hover={{
        opacity: 1,
      }}
    >
      {props.title && (
        <Heading
          as="strong"
          display="flex"
          alignItems="center"
          textTransform="uppercase"
          color="var(--colors-widgetTitle)"
          fontSize="0.875rem"
          userSelect="none"
          mb="1.25rem"
          h="32px"
        >
          {props.title}
        </Heading>
      )}
      {props.content}
    </Flex>
  );
}

export { Widget as default, ListLink, ListShare, Carousel };
