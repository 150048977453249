import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

import { hexToRgba } from '../../utils';

function Card({ children, ...restProps }: BoxProps): React.ReactElement {
  return (
    <Box
      {...restProps}
      position="relative"
      color="var(--colors-cardColor)"
      backgroundColor="var(--colors-cardBackground)"
      boxShadow={`0 0 1rem 0 ${hexToRgba('#000000', 0.05)}`}
      transition="all .1s ease-in-out"
      borderRadius="6"
      w="100%"
      _focus={{
        boxShadow: `0 0 1rem 0 ${hexToRgba('#000000', 0.1)}`,
      }}
      _active={{
        boxShadow: `0 0 1rem 0 ${hexToRgba('#000000', 0.1)}`,
      }}
      _hover={{
        boxShadow: `0 0 1rem 0 ${hexToRgba('#000000', 0.1)}`,
      }}
    >
      {children}
    </Box>
  );
}

export default Card;
