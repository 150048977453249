import React, { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';

import { ONE_MIN_MS, ONE_DAY_MS, getDate, isDateLessThan } from '../../utils';
import { TDate } from './types';

function Date({ createdAt, ...restProps }: TDate): React.ReactElement {
  const [date, setDate] = useState(getDate(createdAt));

  useEffect(() => {
    const timer = isDateLessThan(createdAt, ONE_DAY_MS) && setInterval(() => setDate(getDate(createdAt)), ONE_MIN_MS);
    return () => {
      timer && clearInterval(timer);
    };
  }, [createdAt]);

  return (
    <Flex
      {...restProps}
      textTransform="lowercase"
      __css={{
        '&:first-letter': {
          textTransform: 'uppercase',
        },
      }}
    >
      {date}
    </Flex>
  );
}

export default Date;
