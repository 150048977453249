import React, { useCallback } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { VStack, Box, Link } from '@chakra-ui/react';

import { Position, ListLinkProps, TItem } from '../../types';

const ulListThemes = {
  [Position.Left]: {
    borderRight: '1px solid var(--colors-widgetBorder)',
  },
  [Position.Right]: {
    borderLeft: '1px solid var(--colors-widgetBorder)',
  },
};

const liListThemes = {
  [Position.Left]: {
    p: '0.25rem 1rem 0 0',
  },
  [Position.Right]: {
    p: '0.25rem 0 0 1rem',
  },
};

function ListShare({ position, items }: ListLinkProps): React.ReactElement {
  const ulStyles = ulListThemes[position];
  const liStyles = liListThemes[position];

  const renderItem = useCallback(
    (item: TItem) => (
      <Box {...liStyles} key={item.name} as="li" w="100%">
        <Link
          to={item.to}
          exact={item.exact}
          as={RouterNavLink}
          color="var(--colors-widgetText)"
          transition="color .1s ease-in-out"
          display="inline-flex"
          textDecoration="none"
          fontSize="0.875rem"
          _focus={{
            boxShadow: 'none',
          }}
          _active={{
            color: 'var(--colors-widgetTextHover)',
          }}
          _hover={{
            color: 'var(--colors-widgetTextHover)',
          }}
          activeStyle={{
            color: 'var(--colors-widgetTextActive)',
            cursor: 'default',
            fontWeight: 600,
          }}
        >
          {item.name}
        </Link>
      </Box>
    ),
    [liStyles]
  );

  return (
    <VStack {...ulStyles} as="ul" w="100%" listStyleType="none" _notFirst={{ p: '1rem 0 0 0' }}>
      {items?.map(renderItem)}
    </VStack>
  );
}

export default ListShare;
