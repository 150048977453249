import { Button, IconButton } from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/fi';
import React from 'react';

const NextButton = ({ enabled, onClick }: any) => (
  <Button
    as={IconButton}
    p="0"
    w="30px"
    h="30px"
    top="50%"
    right="1rem"
    position="absolute"
    transform="translateY(-50%)"
    icon={<FiChevronRight size="2rem" />}
    disabled={!enabled}
    onClick={onClick}
    zIndex={1}
    _focus={{
      boxShadow: 'none',
    }}
    sx={{
      display: 'none',
      '@media (min-width: 992px)': {
        display: 'flex',
      },
    }}
  />
);

export default NextButton;
